var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('s-crud',{attrs:{"title":"Impresion QR","filter":_vm.filter,"config":_vm.config},on:{"rowSelected":function($event){return _vm.rowSelected($event)}},scopedSlots:_vm._u([{key:"accion",fn:function({ row }){return [_c('v-btn',{staticStyle:{"border-radius":"13px","margin":"10px 10px 10px 10px"},attrs:{"color":"primary","x-small":"","fab":""},on:{"click":function($event){return _vm.printQR(row)}}},[_c('v-icon',{staticClass:"fas fa-qrcode"})],1)]}}])}),_c('v-row',{staticStyle:{"display":"none"},attrs:{"justify":"center","cols":"12"}},_vm._l((_vm.rowSelect),function(item){return _c('v-col',{attrs:{"cols":"12"}},[_c('qr-code',{attrs:{"text":item.ExpID +
						',' +
						item.EpdQuantityBoxes +
						',' +
						item.CardName +
						',' +
						item.FagName +
						',' +
						item.TypeCropName +
						',' +
						item.TypeCultiveName,"error-level":"H","hidden":""}}),_c('br')],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }