
<template>
	<div>
		<s-crud
			title="Impresion QR"
			:filter="filter"
			:config="config"
			@rowSelected="rowSelected($event)"
		>
			<template v-slot:accion="{ row }">
				<v-btn
					color="primary"
					x-small
					style="border-radius: 13px; margin: 10px 10px 10px 10px;"
					fab
					@click="printQR(row)"
				>
					<v-icon class="fas fa-qrcode"></v-icon>
				</v-btn>
			</template>
		</s-crud>

		<v-row justify="center" cols="12" style="display: none" >
			<v-col cols="12" v-for="item in rowSelect">
				
				<qr-code
					:text="
						item.ExpID +
							',' +
							item.EpdQuantityBoxes +
							',' +
							item.CardName +
							',' +
							item.FagName +
							',' +
							item.TypeCropName +
							',' +
							item.TypeCultiveName 
					"
					error-level="H"
					hidden
				>
				</qr-code>
				<br />
			</v-col>
		</v-row>

	</div>
</template>

<script>
	import _sExportPalletDetailService from '@/services/FrozenProduction/ExportPalletService';
	import qrCode from "vue-qr-generator";

	export default {
		components: {
			qrCode,
		},

		data() {
			return {
				filter: {},
				config: {
					model: {
						ExpID: "ID",
						accion: "string"
					},
					service: _sExportPalletDetailService,
					headers: [
						{text: "Acción", value: "accion"},
						{text: "ID", value: "ExpID"},
						{text: "Cantidad Jabas", value: "UwoMaterialListNumberBoxes"},
						/* {text: "Tipo Selección", value: "TypeSelectionFrozenName"},
						{text: "Categoría", value: "TypeFrozenCategoryName"}, */
						{text: "Presentación", value: "TypePresentationBoxName"},
						{text: "Tipo Cultivo", value: "TypeCropName"},
						{text: "Cultivo", value: "TypeCultiveName"},
						{text: "Variedad", value: "VrtName"},
					]
				},
				rowSelect: [],
			}
		},

		methods: {
			rowSelected(rows)
			{
				this.rowSelect = [];
				if(rows.length > 0){
					this.rowSelect.push(rows[0]);
                    console.log("🚀 ~ file: PrintQRExportPallet.vue ~ line 90 ~ rows[0]", rows[0])
				}
				
			},

			printQR()
			{
				var imgAll = document.querySelector("img");
				console.log('imgAll ', imgAll);
				var Pagelink = "about:blank";
				var pwa = window.open(Pagelink, "_new");
				pwa.document.open();
				pwa.document.write(
					"<html><head><scri" +
						"pt>function step1(){\n" +
						"setTimeout('step2()', 10);}\n" +
						"function step2(){window.print();window.close()}\n" +
						"</scri" +
						"pt><style media='print'>  @media print  { @page { margin-left: 4cm; {} </style></head><body onload='step1()'> \n" +
						"<div class='col col-12 justify-center'>",
				);

				
				/* imgAll.forEach(element => { */
				pwa.document.write(
					"<div class='col col-4' style='margin: 20px 20px 20px 20px;'><img alt='Scan me!' style='display: block;'  src='" +
						imgAll.currentSrc +
						"' /></div>",
				);

				pwa.document.write("<br><br><div ><table border='0'>");
				pwa.document.write(
							"<tr><td colspan='4'>Datos Generales: " + 
							/* "<tr><td>" + "<b>"+this.rowSelect[0].TypeSelectionFrozenName + "</b>"+ */
							/* "</td><td> || " + "<b>"+this.rowSelect[0].TypeFrozenCategoryName + "</b>"+ */
							"</td><td> || " + "<b>"+'N° Cajas: '+this.rowSelect[0].UwoMaterialListNumberBoxes + "</b>"+
							"</td><td> || " + "<b>"+this.rowSelect[0].TypeCropName + "</b>"+
							"</td><td> || " + "<b>"+this.rowSelect[0].TypeCultiveName + "</b>"+
							" </td></tr>",
				);
				pwa.document.write("</table></div>");

				/* this.itemsDetail.forEach(detail => {
						console.log("dedffsfsf", detail);
						pwa.document.write("<br><br><div ><table border='0'>");

						pwa.document.write(
							"<tr><td>Lote Produccion: </td><td>" + detail.RcfID +"</td></tr>" +
							"<tr><td>Jabas: </td><td>" + detail.LlpQuantityJaba +"</td></tr>"
						);

						pwa.document.write(
							"<tr><td>Productor: </td><td>" + detail.PrdCardName +"</td></tr>" +
							"<tr><td>Fundo: </td><td>" + detail.FagName + " || "+ detail.FltName + "</td></tr>"
						);

						pwa.document.write(
							"<tr><td>Certificaciones: </td><td>" + detail.TcDescription +"</td></tr>",
						);

						pwa.document.write("</table></div>");
					}); */

				/* }); */

				pwa.document.write("</div></body></html>");
				pwa.document.close();
			}
		},
	}
</script>
